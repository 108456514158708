.EventsPage{
    width: auto;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin: 2rem;
    align-items: center;
    padding: 2rem;
}

.ep-head{
    font-size: 7rem;
    padding: 1rem 3rem;
    font-weight: bold;
    border-bottom: 2px solid #a3dae0;
    vertical-align: middle;
}

.ep-eventbox{
    height: auto;
    width: 80rem;
    margin: 3rem;
    border-radius: 10px;
    border: 2px solid #a3dae0;
    background-color: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(5px);
    padding: 3rem;
}

.ep-event-title-date{
    /* background-color: #4d4d84; */
    display: flex;
    flex-direction: column;
    gap: 1rem;
    font-size: 2rem;
    font-weight: bold;
    margin: 2rem;
}

.ep-event-title-date h2{
    display: flex;
    align-items: center;
}

.ep-event-title-date p{
    font-size: 1.5rem;
    font-weight: 400;
}

.ep-event-banner{
    border: 2px solid #a3dae08b;
    padding: 5px;
    width: auto;
    border-radius: 10px;
}

.ep-event-banner img{
    width: 100%;
    border-radius: 5px;
}

.ep-event-desc{
    padding: 2rem 0;
    font-size: 1.2rem;
}

.ep-event-photos{
    font-size: 1.2rem;
    padding: 0 0 2rem 0;
}

.ep-ep-gc{
    margin: 2rem 0;
    width: 100%;
    height: calc(width);
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
}

.ep-ep-gi{
    position: relative;
    width: 100%; /* Adjust width as needed */
    padding-top: 100%; /* 1:1 aspect ratio */
    overflow: hidden;
}

.ep-ep-gi img{
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border-radius: 5px;
    object-fit: cover;
}

.ep-event-winners{
    font-size: 1.2rem;
    padding: 0 0 2rem 0;
}

.ep-event-winners ul{
    padding-left: 2rem;
    list-style: none;
    padding: 0;
}

.ep-event-winners ul li::before{
    content: "\2605";
    color: gold;
    display: inline-block;
    width: 1rem;
}

@media only screen and (max-width: 850px){
    .EventsPage{
        margin: 1rem;
    }
    .ep-head{
        font-size: 4rem;
    }
    .ep-eventbox{
        /* height: auto; */
        width: 110%;
        margin: 1rem;
        border-radius: 10px;
        border: 2px solid #a3dae0;
        background-color: rgba(0, 0, 0, 0.6);
        backdrop-filter: blur(5px);
        padding: 1rem;
    }
    .ep-event-title-date{
        display: flex;
        flex-direction: column;
        gap: 1rem;
        font-size: 1rem;
        font-weight: bold;
        margin: 0.5rem;
    }
    .ep-event-title-date p{
        font-size: 1rem;
        font-weight: 400;
    }
    .ep-event-banner img{
        width: 100%;
        border-radius: 5px;
    }
    .ep-event-desc{
        padding: 2rem 0 1rem;
        font-size: 1rem;
    }
    .ep-ep-gc{
        margin: 2rem 0;
        width: 100%;
        height: calc(width);
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 10px;
    }
    .ep-event-winners{
        font-size: 1rem;
        padding: 0 0 1rem 0;
    }
    .ep-event-photos{
        font-size: 1rem;
        padding: 1rem 0 0 0;
    }
}