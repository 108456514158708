.domains-card{
    width: 13rem;
    height: 20rem;
    opacity: 100%;
    color:#a3dae0;
    box-sizing: content-box;
    margin: 2rem;
    padding: 2rem;
    border-radius: 1rem;
    backdrop-filter: blur(5px);
    box-shadow: 0 0px 10px rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.5);
    background-color: rgba(180, 180, 180, 0.1);
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    font-size: 2rem;

    transition: 0.3s ease-in-out;
}

.domains-card h5{
    text-shadow: 0px 0px 5px #000000;
    font-size: 2rem;
    font-weight: 400;
    margin: 0 auto;
}

.domains-card img{
    object-fit: cover;
    align-self: center;
    height: 10rem;
    width: 10rem;
    filter: drop-shadow(0px 0px 10px #69a5bd42);
}

.domains-card:hover{
    color:#a3dae0;
    opacity: 100%;
    width: 14rem;
    height: 20.5rem;
    backdrop-filter: blur(10px);
    box-shadow: 0 0 2px rgba(255, 255, 255, 0.63),
                0 0 10px #ffffff5f;
    background-color: rgba(0, 0, 0, 0.227);
}

.view-more{
    font-weight: 100;
    color: #a3dae0;
    font-size: 1rem;
    align-self: center;
}

.domain-details{
    font-size: 1rem;
}

@media only screen and (max-width: 850px){
    .domains-card{
        box-sizing: border-box;
        width: 18rem;
        height: 20rem;
        color:#a3dae0;
        box-shadow: 0 0 2px rgba(255, 255, 255, 0.63),
                0 0 10px #ffffff5f;
        background-color: rgba(0,0,0,0.227);
        backdrop-filter: blur(10px);
    }
    .domains-card:hover{
        width: 18rem;
        height: 20rem;
    }
}
