.footer{
    height: auto;
    z-index: 999;
    background-color: rgb(0, 0, 0, 0.6);
    backdrop-filter: blur(10px);
    padding: 2rem;
    display: flex;
    justify-content: space-between;
    padding: 2rem;
    border-bottom: 2px solid grey;
}

.footer-form{
    /* background-color: rgba(0, 255, 255, 0.282); */
    width: 30vw;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
}

.footer-form-head {
    /* background-color: green; */
    font-size: 2.1rem;
    font-weight: 100;
    margin-bottom: 0;
}

.footer-form-line{
    /* background-color: red; */
    margin-top: 0;
    margin-bottom: 3rem;
    font-weight: 100;
    text-align: justify;
}

.footer-form form{
    width: auto;
    background-color: white;
    border-radius: 1.2rem;
    height: 2.4rem;
}

.input-email{
    height: 2.4rem;
    width: 20rem;
    box-sizing: border-box;
    margin-left: 1rem;
    border: unset;
    outline: none;
}

.input-email:focus{
    box-sizing: border-box;
    border: unset;
}

.input-email:active{
    border: unset;
}

.input-submit{
    height: 2.2rem;
    width: 5rem;
    box-sizing: border-box;
    border: none;
    color:white;
    background-color: #ce1a1e;
    margin-right: 0.1rem;
    border-radius: 1.1rem;
}

.footer-soc{
    width: 30vw;
    /* background-color: #ffffff2d; */
    padding-top: 3rem;
}

.footer-text-socials{
    text-align: center;
    font-size: 1.2rem;
    font-weight: 100;
    margin-bottom: 1rem;
}

.footer-icon-container{
    margin: auto;
    /* background-color: brown; */
    display: flex;
    width: 20rem;
    justify-content: space-around;
    gap: 1rem;
}

.footer-icons{
    vertical-align: middle;
    color: #ce1a1e;
    font-size: 2rem;
}

.footer-icons:hover{
    color: #a3dae0;
    text-shadow: 0 0 10px white;
    filter: drop-shadow(0 0 10px #a3dae0);
}

.footer-cj{
    width: 30vw;
    margin-top: 2rem;
    text-align: center;
}

.footer-cj img{
    width: 13rem;
    align-self: center;
}

.footer-cj h3{
    font-size: 1.5rem;
    margin-bottom: 0;
}

.footer-cj p{
    margin-top: 0;
    font-weight: 100;
}

.archive{
    display: flex;
    justify-content: center;
    margin-top: 1.5rem;
}

.archive button{
    background-color: #00000000;
    border: none;
    color: #a3dae0;
    border-bottom: 1px solid #a3dae0;
}

.brandassets{
    display: flex;
    justify-content: center;
    margin-top: 1.5rem;
}

.brandassets button{
    background-color: #00000000;
    border: none;
    color: #a3dae0;
    border-bottom: 1px solid #a3dae0;
}

.footer-heart{
    display: inline-block;
    color: white;
    transition: 0.5s ease-in-out;
}

.footer-heart:hover{
    color: red;
    animation: heart 2s linear infinite;

}

@keyframes heart{
    0%{
        transform: scale(1);
    }
    10%{
        transform: scale(1);
    }
    20%{
        transform: scale(1);
    }
    30%{
        transform: scale(1.8);
    }
    40%{
        transform: scale(1);
    }
    50%{
        transform: scale(1);
    }
    60%{
        transform: scale(1);
    }
    70%{
        transform: scale(1.8);
    }
    80%{
        transform: scale(1);
    }
    90%{
        transform: scale(1);
    }
    100%{
        transform: scale(1);
    }
}

@media only screen and (max-width: 850px){
    .footer{
        flex-direction: column;
        gap: 2rem;
    }
    .footer-form{
        width: 80vw;
        /* background-color: rgba(240, 248, 255, 0.103); */
    }
    .footer-form-line{
        text-align: center;
    }
    .input-email{
        width: auto;
    }
    .footer-cj{
        width: 80vw;
        /* background-color: #ce1a1e; */
    }
    .footer-soc{
        width: 80vw;
    }
    .footer-icon-container{
        width: fit-content;
    }
}
