.TQResponse {
    background-color: rgba(0, 0, 0, 0.586);
    border-radius: 10px;
    border: 2px solid rgb(96, 96, 96);
    margin: 5rem auto;
    padding: 1rem;
    /* height: 50vh; */
    width: 50vw;
    backdrop-filter: blur(5px);
    display: flex;
    justify-content: center;
    align-items: safe;
    flex-direction: column;
}

.TQResponse h2{
    padding: 1rem;
    align-self: center;
}

.TQResponse img{
    width: 400px;
    align-self: center;
    padding: 2rem;
}

.TQResponse p{
    padding: 0.2rem 0.5rem;
    text-align: center;
}

.TQResponse a{
    color: red;
    font-weight: bold;
    align-self: center;
}

.TQResponse a button{
    margin-top: 2rem;
    align-self: center;
    padding: 1rem 2rem;
    border-radius: 2rem;
    background-color: red;
    border: none;
    color: white;
    font-size: 1rem;
}


@media only screen and (max-width: 850px) {
    .TQResponse{
        width: 90%;
    }

    .TQResponse h2{
        font-size: large;
        text-align: center;
    }

    .TQResponse p{
        text-align: center;
        padding: 1rem 0;
    }

    .TQResponse img{
        width: 300px;
        align-self: center;
        padding: 2rem;
    }
}

