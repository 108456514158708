.UnderBuild{
    background-color: rgba(0, 0, 0, 0.586);
    border-radius: 10px;
    border: 2px solid rgb(96, 96, 96);
    margin: 5rem auto;
    padding: 1rem;
    /* height: 50vh; */
    width: 50vw;
    backdrop-filter: blur(5px);
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.UnderBuild h2{
    text-align: center;
}

.UnderBuild img{
    align-self: center;
    /* background-color: aqua ; */
    width: 20vw;
    height: auto;
}

.UnderBuild button{
    align-self: center;
    background-color: #00000000;
    color: white;
    font-size: 1.2rem;
    border-radius: 10px;
    padding: 1rem;
    border: 1px solid #a3dae0;
}

@media only screen and (max-width: 850px){
    .UnderBuild{
        width: 80vw;
        gap: 2rem;
    }
    .UnderBuild img{
        width: 60vw;
    }

}