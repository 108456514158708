.nav{
    margin: 0;
    height: 5rem;
    background-color: rgb(0, 0, 0, 0.7);
    backdrop-filter: blur(5px);
    width: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;
    /* border-bottom: 2px solid grey; */
    /* user-select: none; */
}

.logo{
    height: 90%;
    width: auto;
}

.logo img{
    height: 100%;
}

.nav-buttons ul {
    /* overflow-x: visible; */
    list-style: none;
    display: flex;
    gap: 1rem;
    /* background-color: #ce1a1e; */
    /* padding-right: 1rem; */
}

.nav-buttons ul li button.login-btn{
    background-color: #ce1a1e;
    /* border: 2px solid #ae7c54; */
    color: white;
}

.nav-buttons ul li button.register-btn{
    box-shadow:inset 0px 0px 0px 2px #ce1a1e;
    color: white;
}

.nav-buttons ul li button{
    background-color: #ffffff00;
    border: none;
    color: #a3dae0;
    font-size: 1.3rem;
    transition: 0.1s ease;
    cursor: pointer;
    padding: 0.5rem 1rem 0.5rem 1rem;
    align-items: center;
    border-radius: 0.5rem;
}

.nav-buttons ul li button:hover{
    color: white;
    text-shadow: 0px 1px 10px rgba(245, 245, 245, 0.519);
}

.nav-burger-switch{
    display: none;
}

.nav-burger{
    display:none;
}

@media only screen and (max-width: 850px){
    .nav{
        height: 4rem;
    }
    .logo{
        height: 60%;
    }
    .nav-buttons ul{
        display: none;
    }
    .nav-buttons ul li button{
        font-size: 0.7rem;
        /* background-color: #ce1a1e; */
    }
    .nav-burger-switch{
        display: contents;
    }
    .nav-burger{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: end;
        z-index: 99;
    }
    .nav-burger-switch button{
        z-index: 100;
        background-color: #ce000000;
        border: none;
        color: #a3dae0;
        font-size: 2rem;
        align-items: end;
        vertical-align: middle;
    }
    .burger-menu{
        height: auto;
        width: 100vw;
        background-color: #000000;
        transition: 2s ease;
    }
    .burger-menu ul{
        list-style: none;
        /* background-color: #11be11; */
    }
    .burger-menu ul li{
        padding: 0.6rem;
        text-align: center;
        padding-left: 1rem;
        border-bottom: 1px solid grey;
    }
    .burger-menu ul li button.login-btn{
        background-color: #ce1a1e;
        color: white;
        padding: 0.5rem 1rem 0.5rem 1rem;
    }
    .burger-menu ul li button.register-btn{
        box-shadow:inset 0px 0px 0px 2px #ce1a1e;
        color: white;
        padding: 0.5rem 1rem 0.5rem 1rem;
    }
    .burger-menu ul li button{
        font-size: 1rem;
        font-weight: 500;
        background-color: #ffffff00;
        border: none;
        color: #a3dae0;
        font-size: 1rem;
        transition: 0.1s ease;
        cursor: pointer;
        padding: 0.5rem 1rem 0.5rem 1rem;
        align-items: center;
        border-radius: 0.5rem;
    }
}


