.ProjDom{
    height: auto;
    /* display: flex; */
    gap: 2rem;
    margin: 2rem;
    /* align-items: center; */
    /* justify-content:space-around; */
    /* background-color: wheat; */
}

.projDom-head{
    padding: 0 4rem;
    font-size: 8rem;
    font-weight: bolder;
    border-bottom: 2px solid  #a3dae0;
    /* display: flex;
    justify-content:; */
}

.projDoms-domains{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    /* background-color: teal; */
    margin: 2rem auto;
}

@media only screen and (max-width: 850px){
    .ProjDom{
        margin-top: 2rem;
    }
    .projDom-head{
        font-size: 2.45rem;
        padding: 0.5rem 0;
    }
}