.view-upcoming-eve {
    height: auto;
    padding: 2rem;
}

.vue-head {
    font-size: 2rem;
    display: flex;
    justify-content: center;
}

.vue-head h1 {
    padding: 1rem 4rem;
    border-bottom: 2px solid #a3dae0;
}

.vue-container {
    background-color: rgba(0, 0, 0, 0.586);
    border-radius: 10px;
    border: 2px solid rgb(96, 96, 96);
    margin: 4rem auto;
    padding: 1rem;
    /* height: 50vh; */
    width: 90vw;
    backdrop-filter: blur(5px);
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.vue-info {
    font-size: 1.5rem;
    margin-top: 3rem;
}

.vue-info p {
    width: 80%;
    text-align: justify;
    margin: auto;
}

.vue-form {
    width: 80%;
    border: 2px solid #a3dae0a2;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: large;
}

.vue-form form {
    width: 60%;
    display: flex;
    justify-content: center;
    align-items: safe;
    flex-direction: column;
}

.vue-fields{
    margin: 1rem;
    display: flex;
    /* gap: 1rem; */
    justify-content: space-between;
    /* background-color: blue; */
}

.vue-fields input {
    width: 12rem;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 5px;
}

.vue-fields label {
    color: white;
    width: 10rem;
    padding: 0.5rem 1rem;
}

.vue-fields select {
    width: 12rem;
    border: none;
    border-radius: 5px;
    padding: 0.5rem 1rem;
}

.vue-form-submit {
    margin: 1rem;
    width: max-content;
    padding: 0.5rem 1rem;
    align-self: center;
    font-size: 1rem;
    border: none;
    border-radius: 5px;
    background-color: red;
    color: white;
}

.loading-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.657);
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    z-index: 999;
    backdrop-filter: blur(5px);
}

.ls-ring{
    border: 5px solid #a3dae0;
    border-left: 5px solid #a3dae03f;
    width: 50px;
    height: 50px;
    border-radius: 25px;
    margin: 1rem;
    animation: rotate 1s linear infinite;
}

@keyframes rotate {
    0%{
        transform: rotate(0deg);
    }
    100%{
        transform: rotate(360deg);
    }
}


@media only screen and (max-width: 850px) {
    .view-upcoming-eve {
        padding: 1rem;
    }

    .vue-head {
        text-align: center;
        font-size: 0.9rem;
        margin-top: 2rem;
        /* background-color: #fff; */
    }

    .vue-head h1 {
        padding: 0.5rem;
    }

    .vue-container {
        width: auto;
    }

    .vue-info {
        font-size: 1.3rem;
        padding: 1rem;
    }

    .vue-info p {
        width: 100%;
    }

    .vue-form {
        width: 100%;
    }

    .vue-form form span {
        flex-direction: column;
    }

    .vue-fields label {
        padding: 0.5rem 0.1rem;
    }

    .vue-fields input {
        padding: 0.5rem 0.5rem;
        width: 100%;
    }

    .vue-fields select {
        width: 100%;
        padding: 0.5rem 0.1rem;
    }
}