.ArchList{
    border: 1px solid #a3dae0;
    border-radius: 10px;
    padding: 1rem;
    margin: 1rem;
    width: 40vw;
    background: transparent;
    color: #a3dae0;
    backdrop-filter: blur(10px);
    background-color: rgba(0, 0, 0, 0.2);
}

.AL-title h2{
    /* background-color: red; */
    font-weight: 500;
    padding: 0.5rem;
}

.AL-date{
    padding: 0.2rem;
    color: #f6f6f6ad;
}

@media only screen and (max-width: 850px){
    .ArchList{
        width: 80vw;
    }
}