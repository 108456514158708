*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
#main-body{
  background-color: #00111d;
  color: #bdf8ff;
  user-select: none;
}

::-webkit-scrollbar {
  display: none;
}